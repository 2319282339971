import Head from 'next/head';
import { Routes } from '@/constants/routes';

export const Home = () => {
  return (
    <Head>
      <title>RelayPay</title>
    </Head>
  );
};

export default Home;
